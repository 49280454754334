<template>
  <div>
    <div class="row" v-if="events === undefined">
      <div class="col-3"></div>
      <div class="col-9">
        <div class="nature-white-box no-min-height mb-3">
          <div class="row mt-3 mb-4 ms-2" v-for="i in 4" :key="i">
            <div class="col">
              <h2>
                <Skeletor :width="Math.random() * 21 + 30 + '%'" />
              </h2>
              <div>
                  <span>
                    <Skeletor :width="Math.random() * 10 + 10 + '%'" />
                  </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-3">
        <div class="nature-white-box no-min-height mb-3" v-if="data">
          <h2 class="mb-2">{{ $t("session.session") }}</h2>
          <div class="row">
            <div class="col-4 regular-16">{{ $t("session.type") }}</div>
            <div class="col-8 bold-16">{{data.device_type}}</div>
          </div>
          <div class="row">
            <div class="col-4 regular-16">{{ $t("session.platform") }}</div>
            <div class="col-8 bold-16">{{data.platform}}</div>
          </div>
          <div class="row">
            <div class="col-4 regular-16">{{ $t("session.browser") }}</div>
            <div class="col-8 bold-16">{{data.browser}}</div>
          </div>
          <div class="row" v-if="data.device">
            <div class="col-4 regular-16">{{ $t("session.device") }}</div>
            <div class="col-8 bold-16">{{data.device}}</div>
          </div>
          <div class="row" v-if="data.loacation?.country">
            <div class="col-4 regular-16">{{ $t("orderChange.country") }}</div>
            <div class="col-8 bold-16">{{data.loacation?.country}}</div>
          </div>
        </div>
        <div class="nature-white-box no-min-height mb-3" v-if="user">
          <h2 class="mb-2">{{ $t("session.user") }}</h2>
          <div class="row" v-if="user.name">
            <div class="col-4 regular-16">{{ $t("session.name") }}</div>
            <div class="col-8 bold-16">
              {{ user.name }}
            </div>
          </div>
          <div class="row" v-if="user.first_name">
            <div class="col-4 regular-16">{{ $t("session.name") }}</div>
            <div class="col-8 bold-16">
              {{ user.first_name }} {{ user.last_name }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="nature-white-box no-min-height mb-3">
          <div class="timeline mt-5 me-4">
            <div
                class="timeline-item"
                v-for="event in events"
                :key="event.id"
            >
              <div class="timeline-time">{{ formatDate(event.created_at) }}</div>
              <div class="timeline-status">
                <div
                    class="timeline-status-icon primary bg-gradient"
                >
                  <BaseIcon name="bag" />
                </div>
              </div>
              <div class="timeline-data">
                <h4 class="bold-19 mt-2">{{ event.event_name }}</h4>
                {{ event.source_url }}
                <div v-if="event.order">
                  <b>
                    {{ event.order.pretty_id }}
                  </b>
                  <EditOnClick
                    :model-value="{
                      amount: event.order.payment_price,
                      currency: event.order.currency_code,
                    }"
                    type="price"
                    :editable="false"
                  />
                </div>
                <div v-if="event.productVariant">
                  <div>
                    <b>{{event.productVariant.serial}}</b>
                  </div>
                  <div>
                    {{event.productVariant.sku}} / {{event.productVariant.name}}
                  </div>
                </div>
                <div v-if="event.payload?.price && event.payload?.currency">
                  <EditOnClick
                    :model-value="{
                      amount: event.payload.price,
                      currency: event.payload.currency,
                    }"
                    type="price"
                    :editable="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import BaseIcon from "../../components/icons/BaseIcon";
import date from "../../modules/date";
import EditOnClick from "../../components/inputs/EditOnClick";

export default {
  name: "Session",
  components: {EditOnClick, BaseIcon},
  data() {
    return {
      data: undefined,
      events: undefined,
      user: undefined,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      http.fetch(`/sessions/${this.$route.params.id}`).then((data) => {
        this.events = data.events;
        this.data = data.data;
        this.user = data.user;
      });
    },
    formatDate(val) {
      return date.format(val);
    },
  },
};
</script>
